.goat {
  background-color: var(--yellow);
  background-image: url('./images/GOAT.jpg');
  background-repeat: no-repeat;
  background-size: 1680px;
  background-position: center center;

  @media (min-width: 600px) {
    background-size: 1830px;
  }
}
