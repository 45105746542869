$cheers-bp-1: 1000px;
$cheers-bp-2: 1382px;

.cheers {
  background-color: var(--pink);

  [class^='cheers__drink'] {
    position: absolute;
    z-index: 2;
    opacity: 0;
    width: 100%;
  }

  &__drink-1 {
    bottom: -15%;
    left: -12%;
    max-width: 54vw;
    transform: scaleX(-1);
    animation: drinkUp 0.5s forwards ease;
    animation-delay: 0.5s;

    @media (min-width: $cheers-bp-1) {
      left: -5%;
      max-width: 43vw;
    }

    @media (min-width: $cheers-bp-2) {
      animation: drinkUp3 0.5s forwards ease;
      max-width: 539px;
      left: 10%;
      transform: scaleX(1);
    }
  }

  &__drink-2 {
    bottom: -10%;
    right: -10%;
    max-width: 73vw;
    animation: drinkUp 0.5s forwards ease;

    @media (min-width: $cheers-bp-1) {
      right: -5%;
      animation: drinkUp2 0.5s forwards ease;
    }
    @media (min-width: $cheers-bp-1) {
      right: 10%;
      max-width: 498px;
    }
  }

  &__fizz {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    z-index: 1;
    > * {
      height: 20px;
      width: 20px;
      background-color: var(--blue);
      position: absolute;
      border-radius: 50%;
      opacity: 0;

      @for $i from 1 through 15 {
        &:nth-child(#{$i}) {
          $delay: $i * 0.5s;
          z-index: $i;
          top: random(90) * 1%;
          left: random(90) * 1%;
          animation: bubbleUp 4s forwards infinite $delay ease-out;
          transform: scale(random(2) / 2.5) translate(-50%, -50%);
        }
      }

      &:nth-child(odd) {
        background-color: var(--yellow);
      }
    }
  }
}

@keyframes drinkUp {
  0% {
    opacity: 0;
    bottom: -18%;
  }
  25% {
    opacity: 1;
  }
  85% {
    bottom: -13%;
  }
  100% {
    opacity: 1;
    bottom: -15%;
  }
}

@keyframes drinkUp2 {
  0% {
    opacity: 0;
    bottom: 13%;
  }
  25% {
    opacity: 1;
  }
  85% {
    bottom: 17%;
  }
  100% {
    opacity: 1;
    bottom: 15%;
  }
}

@keyframes drinkUp3 {
  0% {
    opacity: 0;
    bottom: -6%;
  }
  25% {
    opacity: 1;
  }
  85% {
    bottom: 0%;
  }
  100% {
    opacity: 1;
    bottom: -4%;
  }
}

@keyframes bubbleUp {
  0% {
    margin-top: 0;
    margin-left: 0;
    opacity: 0;
    width: 3px;
    height: 3px;
  }
  10% {
    margin-top: -2px;
    opacity: 1;
    margin-left: 0;
    width: 20px;
    height: 20px;
  }

  25% {
    margin-left: random(7) * -1px;
  }

  50% {
    margin-left: random(7) * 1px;
    opacity: 1;
  }

  70% {
    margin-left: random(7) * -1px;
    width: 20px;
    height: 20px;
  }

  100% {
    margin-top: -40px;
    opacity: 0;
    margin-left: 0;
    width: 3px;
    height: 3px;
  }
}
