$footer-bp-1: 600px;
$footer-bp-2: 768px;
$footer-bp-3: 960px;
$footer-bp-4: 1100px;

.footer {
  background-color: var(--blue);
  position: relative;
  overflow: hidden;

  &__container {
    padding: 1.5rem;
    max-width: 1137px;
    margin: 0 auto;

    @media (min-width: $footer-bp-2) {
      display: grid;
      grid-template-columns: 90px 5fr;
      gap: 0 1.5rem;
    }

    @media (min-width: $footer-bp-4) {
      grid-template-columns: 226px 5fr;
      padding: 6.4rem 1.5rem 0;
    }

    * {
      color: var(--yellow);
      font-family: var(--sans);
      font-weight: var(--sans-bold);
      font-size: 1.125rem;
      text-transform: uppercase;
      letter-spacing: 0.4px;
    }

    p {
      max-width: 21.25rem;
      margin: 0;

      @media (min-width: $footer-bp-1) {
        max-width: 100%;
      }
    }
  }

  &__image {
    @media (min-width: $footer-bp-4) {
      text-align: center;

      svg {
        position: relative;
        top: -3.1rem;
        display: inline-block;
        transform: scale(2.52);
      }
    }
  }

  &__content {
    max-width: 1067px;
    margin: 0 auto;

    > * {
      margin-top: 1rem;
    }

    @media (min-width: $footer-bp-1) {
      display: grid;
      grid-template-columns: 245px 1fr;
      gap: 0 1.5rem;
    }

    @media (min-width: $footer-bp-3) {
      grid-template-columns: 245px 1fr 110px;

      > * {
        margin-top: 0;
      }
    }

    &__3 {
      @media (min-width: $footer-bp-1) {
        grid-column-start: 2;
      }

      @media (min-width: $footer-bp-3) {
        grid-column-start: 3;
      }
    }
  }
}
