.introduction {
  height: 100vh;
  min-height: 755px;
  width: 100vw;
  position: relative;
  overflow: hidden;

  &__record {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 203px;
    height: 203px;
    margin-top: 80px;
    animation: recordSlide 0.4s ease-out 0.5s forwards;

    @media (min-width: 600px) {
      top: 29%;
      height: 204px;
      width: 204px;
      transform: translate(-50%, -50%) scale(1.63);
    }

    @media (min-width: 1000px) and (min-height: 860px) {
      top: 24%;
      height: 204px;
      width: 204px;
      transform: translate(-50%, -50%) scale(1.9);
    }

    img {
      display: inline-block;
      animation: rotating 6s linear infinite;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    position: absolute;
    top: 34%;
    text-align: center;
    font-family: var(--sans);
    font-weight: var(--sans-heavy);
    color: var(--blue);
    font-size: 3rem;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 0;
    z-index: 3;
    width: 100%;
    padding: 0 8px;
    margin: 0;
    animation: fadeIn 0.4s ease-in-out;

    @media (min-width: 380px) {
      padding: 0 24px;
    }

    @media (min-width: 600px) {
      font-size: 5rem;
      top: 42%;
    }

    @media (min-width: 1000px) and (min-height: 860px) {
      font-size: 9.5rem;
      top: calc(50% - 150px);
    }
  }

  &__copy {
    text-align: center;
    font-family: var(--sans);
    font-weight: var(--sans-heavy);
    color: var(--blue);
    font-size: 1.375rem;
    margin-top: 1rem;
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    width: 100%;
    max-width: 28.75rem;
    padding: 0 1.5rem;
    margin: 0;
    text-transform: uppercase;

    @media (min-width: 500px) {
      padding: 0;
    }

    @media (min-width: 600px) {
      top: 75%;
    }

    .link {
      color: var(--pink);
      text-decoration: none;
    }
  }

  &__box {
    top: 40%;
    background-color: var(--white);
    border: 2px solid var(--blue);
    z-index: 2;
    height: 204px;
    width: 204px;

    @media (min-width: 600px) {
      top: 50%;
      height: 340px;
      width: 340px;
    }

    @media (min-width: 1000px) and (min-height: 860px) {
      height: 400px;
      width: 400px;
    }
  }

  &__arrow {
    position: absolute;
    bottom: 110px;
    left: 50%;
    transform: translate(-50%);

    @media (min-width: 600px) {
      bottom: 54px;
    }

    @media (min-height: 860px) {
      bottom: 80px;
    }

    .scroll-to {
      display: inline-block;
      transform: translateY(0);
      transition: transform 0.25s ease;

      &:hover {
        transform: translateY(6px);
      }
    }
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  75% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes recordSlide {
  0% {
    margin-top: 80px;
  }
  100% {
    margin-top: 0px;
  }
}
