$nuts-bp-1: 600px;
$nuts-bp-2: 1000px;

.nuts {
  background-color: var(--pink);
  background-image: url('./images/nuts-tree-mobile.jpg');
  background-repeat: no-repeat;
  background-size: 600px;
  background-position: center 140px;

  @media (min-width: $nuts-bp-1) {
    background-image: url('./images/nuts-tree-large.jpg');
    background-size: 1800px;
    background-position: center 0px;
  }

  &__squirrel {
    position: absolute;
    width: 150px;
    height: auto;
    animation: squirlley1 20s backwards infinite;

    img {
      width: 150px;
      animation: bobble 2s backwards infinite;

      @media (min-width: $nuts-bp-2) {
        width: 200px;
      }
    }
  }
}

@keyframes squirlley1 {
  $top1: random(60) * 1%;
  $bottom1: random(60) * 1%;
  $left1: random(60) * 1%;
  $right1: random(60) * 1%;
  $outside: -1200px;
  // ---------- top random
  0% {
    bottom: unset;
    top: $outside;
    left: $top1;
    transform: rotate(180deg);
  }

  4% {
    bottom: unset;
    top: -110px;
    left: $top1;
    transform: rotate(180deg);
  }

  8% {
    bottom: unset;
    top: -110px;
    left: $top1;
    transform: rotate(180deg);
  }

  10% {
    bottom: unset;
    top: -610px;
    left: $top1;
    transform: rotate(180deg);
  }

  // ---------- bottom
  20% {
    top: unset;
    bottom: -610px;
    left: $outside;
    transform: rotate(0deg);
  }
  21% {
    top: unset;
    bottom: -610px;
    left: $bottom1;
    transform: rotate(0deg);
  }

  22% {
    // pop out
    top: unset;
    bottom: -130px;
    left: $bottom1;
    transform: rotate(0deg);
  }

  30% {
    top: unset;
    bottom: -130px;
    left: $bottom1;
    transform: rotate(0deg);
  }

  32% {
    top: unset;
    bottom: -610px;
    left: $bottom1;
    transform: rotate(0deg);
  }

  // ---------- left
  34% {
    bottom: -610px;
    left: $outside;
    transform: rotate(0deg);
  }
  35% {
    top: $left1;
    bottom: unset;
    left: $outside;
    transform: rotate(90deg);
  }

  36% {
    // pop out
    top: $left1;
    bottom: unset;
    left: -40px;
    transform: rotate(90deg);
  }

  46% {
    top: $left1;
    bottom: unset;
    left: -40px;
    transform: rotate(90deg);
  }

  47% {
    top: $left1;
    bottom: unset;
    left: $outside;
    transform: rotate(90deg);
  }

  // ---------- right
  48% {
    // top left corner
    top: $outside;
    bottom: unset;
    left: $outside;
    transform: rotate(90deg);
  }

  50% {
    // top right corner
    top: $outside;
    left: unset;
    right: $outside;
    transform: rotate(-90deg);
  }

  51% {
    // in position right side
    left: unset;
    top: $right1;
    right: $outside;
    transform: rotate(-90deg);
  }

  52% {
    // hold position right side
    left: unset;
    top: $right1;
    right: $outside;
    transform: rotate(-90deg);
  }

  56% {
    // pop out right side
    left: unset;
    top: $right1;
    right: -70px;
    transform: rotate(-90deg);
  }

  66% {
    // hold pop out right side
    left: unset;
    top: $right1;
    right: -70px;
    transform: rotate(-90deg);
  }

  76% {
    // in position right side
    left: unset;
    top: $right1;
    right: $outside;
    transform: rotate(-90deg);
  }

  // transition to end

  80% {
    // in position right side
    top: $right1;
    right: $outside;
    transform: rotate(-90deg);
  }

  85% {
    // in position right side
    top: $outside;
    right: unset;
    left: $outside;
    transform: rotate(0deg);
  }

  // ---------- end
  90% {
    top: $outside;
    right: unset;
    left: $outside;
    transform: rotate(0deg);
  }
  100% {
    top: $outside;
    right: unset;
    left: $outside;
    transform: rotate(0deg);
  }
}

@keyframes bobble {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(10deg);
  }

  75% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
