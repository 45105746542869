@import url('https://use.typekit.net/zkg4ndk.css');

:root {
  --sans: trade-gothic-next, sans-serif;
  --sans-reg: 400;
  --sans-bold: 700;
  --sans-heavy: 800;

  // colors
  --white: #ffffff;
  --blue: #0b2eff;
  --pink: #ff1dc3;
  --yellow: #f7f77a;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

.embed {
  height: 244px;
  width: 244px;
  position: relative;
  z-index: 20;

  @media (min-width: 600px) {
    height: 340px;
    width: 340px;
  }

  @media (min-width: 1000px) {
    height: 400px;
    width: 400px;
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

// utility
.centered {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

section {
  height: 100vh;
  min-height: 755px;
  max-height: 1000px;
  width: 100vw;
  position: relative;
  overflow: hidden;
}
