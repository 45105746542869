.embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 19;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    transition: opacity 0.4s ease-out;
    opacity: 0;
  }

  &.show {
    &::before {
      opacity: 1;
    }

    button {
      pointer-events: auto;
      opacity: 1;
    }
  }

  iframe {
    pointer-events: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 244px;
    width: 244px;

    @media (min-width: 600px) {
      height: 340px;
      width: 340px;
    }

    @media (min-width: 1000px) {
      height: 400px;
      width: 400px;
    }
  }

  button {
    width: 90px;
    height: 90px;
    appearance: none;
    border: 0;
    background: none;
    position: absolute;
    top: 20px;
    right: 20px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.4s ease-out;
    cursor: pointer;

    img {
      position: static;
    }
  }
}
