$best-bp-1: 1100px;
$best-bp-2: 1440px;

.best {
  background-color: var(--yellow);

  &__waves {
    --waveBox: 400px;
    height: var(--waveBox);
    width: var(--waveBox);
    position: absolute;
    top: 0;
    left: 0;

    @media (min-width: $best-bp-1) {
      --waveBox: 700px;
    }

    @media (min-width: $best-bp-2) {
      --waveBox: 1000px;
    }

    img {
      position: absolute;

      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          $delay: $i * 0.5s;
          z-index: $i;
          animation: waves 8s ease-in-out alternate infinite $delay;

          @media (min-width: $best-bp-2) {
            animation: waves-large 8s ease-in-out alternate infinite $delay;
          }
        }
      }

      &.best__waves__1 {
        width: calc(var(--waveBox) / 3.75);
        top: -10%;
        left: -10%;
      }

      &.best__waves__2 {
        width: calc(var(--waveBox) / 2.25);
        top: -17%;
        left: -16%;
      }

      &.best__waves__3 {
        width: calc(var(--waveBox) / 1.5);
        top: -32%;
        left: -20%;
      }

      &.best__waves__4 {
        width: var(--waveBox);
        top: -60%;
        left: -28%;
      }
    }
  }

  &__trophy {
    --trophyBox: 400px;
    height: calc(var(--trophyBox) * 1.333);
    width: var(--trophyBox);
    position: absolute;
    bottom: 0;
    right: 0;

    @media (min-width: $best-bp-1) {
      --trophyBox: 700px;
    }

    &__1 {
      position: absolute;
      width: var(--trophyBox);
      bottom: -44%;
      right: -30%;
      animation: float 5s alternate infinite;
    }
  }
}

@keyframes waves {
  0% {
    transform: scale(1) translate(0, 0);
  }
  25% {
    transform: scale(0.9) translate(0, 0);
  }
  75% {
    transform: scale(1.1) translate(0, 0);
  }
  100% {
    transform: scale(1) translate(0, 0);
  }
}

@keyframes waves-large {
  0% {
    transform: scale(1) translate(0, 0);
  }
  25% {
    transform: scale(0.95) translate(0, 0);
  }
  75% {
    transform: scale(1.005) translate(0, 0);
  }
  100% {
    transform: scale(1) translate(0, 0);
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-1%);
  }
}
