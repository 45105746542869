.box {
  background-color: var(--blue);
  height: 244px;
  width: 244px;
  position: relative;
  z-index: 20;
  transition: opacity 0.4s ease-out;

  @media (min-width: 600px) {
    height: 340px;
    width: 340px;
  }

  @media (min-width: 1000px) {
    height: 400px;
    width: 400px;
  }

  &.hide {
    pointer-events: none;
    opacity: 0;
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90px;
    height: 90px;
    appearance: none;
    border: 0;
    background: none;
    transition: opacity 0.2s ease-out;
    cursor: pointer;

    &:hover,
    &:focus {
      opacity: 0.65;
    }
  }
}
