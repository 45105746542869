$happy-bp-1: 600px;
$happy-bp-2: 960px;
$happy-bp-3: 1320px;

.happy {
  background-color: var(--yellow);

  &__face {
    img {
      --happyScale: 400px;

      @media (min-width: $happy-bp-1) {
        --happyScale: 600px;
      }

      @media (min-width: $happy-bp-2) {
        --happyScale: 700px;
      }

      @media (min-width: $happy-bp-3) {
        --happyScale: 800px;
      }
      max-width: var(--happyScale);
    }
  }

  [class^='happy__clown'] {
    position: absolute;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      z-index: 2;
    }

    &::before {
      content: '';
      background-color: var(--pink);
      border-radius: 100%;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }

  &__clown-1 {
    --clown1: 300px;
    bottom: 6%;
    left: 5%;
    height: calc(var(--clown1) / 2);
    width: calc(var(--clown1) / 3);

    animation: clowning 2s alternate infinite;

    &::before {
      height: calc(var(--clown1) / 2.6);
      width: calc(var(--clown1) / 2.6);
    }

    @media (min-width: $happy-bp-2) {
      --clown1: 400px;
    }

    @media (min-width: $happy-bp-3) {
      --clown1: 450px;
      bottom: 16%;
    }
  }

  &__clown-2 {
    --clown2: 100px;
    bottom: 8%;
    right: 1%;
    height: calc(var(--clown2) / 1.5);
    width: var(--clown2);

    animation: clowning 2s alternate infinite;

    @media (min-width: $happy-bp-2) {
      --clown2: 150px;
      bottom: 17%;
    }

    @media (min-width: $happy-bp-3) {
      --clown2: 184px;
      right: 5%;
    }

    &::before {
      height: calc(var(--clown2) * 1.75);
      width: calc(var(--clown2) / 1.25);
    }
  }

  &__clown-3 {
    --clown3: 250px;
    top: 3%;
    right: 1%;
    height: calc(var(--clown3) / 1.8);
    width: calc(var(--clown3) / 2);

    animation: clowning 5s alternate infinite;

    @media (min-width: $happy-bp-2) {
      top: 12%;
    }

    @media (min-width: $happy-bp-3) {
      right: 10%;
    }

    &::before {
      height: calc(var(--clown3) / 2.5);
      width: calc(var(--clown3) / 2.5);
    }
  }
}

@keyframes clowning {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5%);
  }
}
