.thanks {
  background-color: var(--yellow);
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>✍️</text></svg>")
      0 0,
    auto;

  height: 100vh;
  max-height: unset;
  width: 100vw;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (min-width: 600px) {
      display: none;
    }
  }

  #thanks-canvas {
    display: none;
    height: 100vh;
    width: 100vw;

    @media (min-width: 600px) {
      display: block;
    }
  }
}
