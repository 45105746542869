$cellsize: 142px;

.nice {
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: var(--blue);
  overflow: hidden;

  .box {
    z-index: 9999999;
  }

  &__rows {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 50%;
    bottom: 0;
    left: 0;
    transform: scale(2) rotate(45deg) translate(-50%, -50%);

    &__item {
      $rowTime: 0.25s;
      height: $cellsize - 60px;
      width: 200vw;
      position: relative;
      top: 0;
      left: 0;
      filter: hue-rotate(0deg);
      transform: rotate3d(0, 0, 0, 0deg);
      transition: transform 1s ease-in, filter 1s ease-in;

      &::before {
        content: '';
        position: absolute;
        height: $cellsize - 60px;
        width: 200vw;
        top: 40px;
        left: 0;
        background-color: darken($color: #0b2eff, $amount: 20%);
        filter: blur(13.55px);
        margin-top: 40px;
        transition: margin 2s ease-out;
      }

      // not supported by safari
      // &:hover {
      //   transition: transform $rowTime ease-out, filter $rowTime ease-out;
      //   filter: hue-rotate(34deg);
      //   transform: rotate3d(92, 3, 2, -10deg);

      //   &::before {
      //     margin-top: 0;
      //     transition: margin 0.1s ease-out;
      //   }
      // }

      @for $i from 1 through 20 {
        &:nth-child(#{$i}) {
          $delay: $i * 0.5s;
          z-index: $i;

          .nice__rows__item__image {
            animation: float-1 4s alternate infinite $delay;
          }
        }
      }

      &:nth-child(odd) {
        .nice__rows__item__image {
          background-position: -20px 0;
        }
      }

      &:nth-child(even) {
        .nice__rows__item__image {
          background-position: 20px 0;
        }
      }

      &__image {
        background-image: url('./images/nice-cell.svg');
        display: block;
        position: relative;
        overflow: hidden;
        height: $cellsize;
        width: 400vw;
        background-repeat: repeat-x;

        @for $i from 1 through 20 {
          $space: -140px;
          &:nth-child(#{$i}) {
            // animation: float-1 4s alternate infinite $i * 1s;
          }
        }
      }
    }
  }
}

@keyframes float-1 {
  0% {
    transform: skewX(0) scale(1);
  }
  100% {
    transform: skewX(2deg) skewY(0.2deg);
  }
}

@keyframes float-2 {
  0% {
    transform: skewX(0) scale(1);
  }
  100% {
    transform: skewX(-2deg) skewY(-0.2deg);
  }
}
