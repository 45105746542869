$bp-lets-1: 415px;
$bp-lets-2: 600px;
$bp-lets-3: 750px;
$bp-lets-4: 1600px;

$lets-bg: #fff1fc;
.lets {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  background-color: $lets-bg;

  &::after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 400px;
    right: 0;
    left: 0;
    height: 50vh;
    background: linear-gradient(
      180deg,
      rgba(255, 241, 252, 0) 0%,
      rgba(255, 241, 252, 0.727328431372549) 8%,
      rgba(255, 241, 252, 1) 15%,
      rgba(255, 241, 252, 1) 100%
    );

    @media (min-width: $bp-lets-1) {
      height: 58vh;
      background: linear-gradient(
        180deg,
        rgba(255, 241, 252, 0) 0%,
        rgba(255, 241, 252, 1) 25%,
        rgba(255, 241, 252, 1) 100%
      );
    }

    @media (min-width: $bp-lets-1) {
      height: 45vh;
    }

    @media (min-width: $bp-lets-3) {
      display: none;
    }
  }

  img {
    position: absolute;
  }

  &__img1 {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    transform: translateX(-40%);
    width: 500px;

    > img {
      transform: translateX(-50%);
    }

    @media (min-width: $bp-lets-1) {
      transform: translateX(-50%);
      width: 600px;

      > img {
        transform: translate(-50%, -5%);
      }
    }

    @media (min-width: $bp-lets-2) {
      width: 750px;
      top: -60px;
    }

    @media (min-width: $bp-lets-3) {
      width: auto;
      max-width: unset;
      height: 100vh;
      top: 0;
      left: 25%;

      > img {
        height: 1000px;
        width: auto;
        transform: translate(-50%, 0);
      }
    }

    @media (min-width: $bp-lets-4) {
      left: 0;

      > img {
        transform: translate(0, 0);
      }
    }
  }

  &__eyes {
    width: 603px;
    height: 135px;
    position: absolute;
    left: 50%;
    z-index: 2;
    transform: translate(-58%, 84%) rotate(29deg) scale(0.4);

    @media (min-width: $bp-lets-1) {
      top: 132px;
      transform: translate(-59.9%, -12%) rotate(29deg) scale(0.5);
    }

    @media (min-width: $bp-lets-2) {
      width: 579px;
      transform: translate(-63%, -20%) rotate(29deg) scale(0.6);
    }

    @media (min-width: $bp-lets-3) {
      top: 359px;
      transform: translate(-68.9%, -40.2%) rotate(29deg) scale(0.9);
    }

    @media (min-width: $bp-lets-4) {
      transform: translate(23%, -40.2%) rotate(29deg) scale(0.83);
    }

    &::before {
      content: '';
      background-color: #f6f67a;
      opacity: 0.7;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      animation: strobe 3s alternate infinite;
    }

    img {
      max-width: 121px;
      top: 50%;
      transform: rotate(-29deg) translateY(-60%);
    }

    &__left {
      left: 23%;
      display: inline-block;
    }

    &__right {
      right: 10%;
      display: inline-block;
    }
  }

  &__img2 {
    width: 390px;
    z-index: 13;
    left: -180px;
    bottom: 6%;
    animation: flower1 5s alternate infinite;

    @media (min-width: $bp-lets-1) {
      left: -220px;
      width: 600px;
      bottom: -150px;
    }

    @media (min-width: $bp-lets-3) {
      top: -17vw;
      right: -12vw;
      width: 52vw;
      max-width: 800px;
      bottom: unset;
      left: unset;
      animation: flower1-large 5s alternate infinite;
    }
  }

  &__img3 {
    width: 390px;
    z-index: 4;
    right: -31%;
    bottom: -3%;
    transform: rotate(245deg) scaleX(-1);
    animation: flower2 5s alternate infinite;

    @media (min-width: $bp-lets-1) {
      width: 420px;
      bottom: -70px;
      right: -160px;
    }

    @media (min-width: $bp-lets-1) {
      width: 666px;
      right: -230px;
      bottom: -170px;
    }

    @media (min-width: $bp-lets-3) {
      right: -300px;
      bottom: 0px;
    }

    @media (min-width: 1200px) {
      right: -15vw;
      bottom: -6vw;
      width: 45vw;
      max-width: 800px;
      animation: flower2-large 5s alternate infinite;
    }
  }
}

@keyframes strobe {
  0% {
    transform: scale(1);
    filter: hue-rotate(0);
  }
  100% {
    transform: scale(1.05);
    filter: hue-rotate(-24deg);
  }
}

@keyframes flower1 {
  0% {
    transform: translateY(0) rotate(135deg);
  }
  100% {
    transform: translateY(-8px) rotate(135deg);
  }
}

@keyframes flower1-large {
  0% {
    transform: translateY(0) rotate(-53deg);
  }
  100% {
    transform: translateY(-8px) rotate(-53deg);
  }
}

@keyframes flower2 {
  0% {
    transform: translateY(0) rotate(245deg) scaleX(-1);
  }
  100% {
    transform: translateY(8px) rotate(245deg) scaleX(-1);
  }
}

@keyframes flower2-large {
  0% {
    transform: translateY(0) rotate(-34deg);
  }
  100% {
    transform: translateY(8px) rotate(-34deg);
  }
}
